/* eslint-disable no-loop-func */
/* eslint-disable consistent-return */
/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */
import { SegmentAndRules, TargetingCriteria } from 'src/types/core';
import {
  CriteriaParentRule,
  CriteriaRuleGroup,
  CriteriaRuleType,
  CriteriaSegment,
  CriteriaChildRule,
  isParentRule,
  CriteriaAnyRule,
  CriteriaUnifiedTargeting,
  CriteriaUnifiedAttributes,
} from '../types';
import encodeEventKey from './encode_event_key';
import { gguid, getAttributeType, getDefaultDetailsForType } from './index';

const ruleTypeFromValue = (value: any): CriteriaRuleType => {
  const valueType = typeof value;

  switch (valueType) {
    case 'string':
    case 'number':
    case 'boolean':
      return valueType;

    default:
      console.warn('Unrecognized Criteria Value:');
      return 'string';
  }
};

function flattenComplexValue(value: unknown): unknown {
  if (typeof value === 'object' && typeof (value as { _type: 'string' })?._type !== 'undefined') {
    const complexValue = value as
      | {
          _type: 'version';
          version: string;
        }
      | {
          _type: 'datetime' | 'duration';
          sec: number;
        };
    if (complexValue._type === 'datetime' || complexValue._type === 'duration') {
      return complexValue.sec;
    }
    if (complexValue._type === 'version') {
      return complexValue.version;
    }
  }

  return value;
}

/**
 * Convert rules to a details structure
 *
 * @param options The options
 * @param options.values The current grouping of rules to process.
 * @param options.unifiedTargeting The collection of possible values for Interactions & Interaction Response Targeting.
 * @param options.unifiedAttributes The collection of custom person & device data attributes to populate rules with.
 * @returns The new criteria format for the provided grouping of rules.
 */
export const consolidateRules = ({
  values,
  unifiedTargeting,
  unifiedAttributes,
}: {
  values: SegmentAndRules['$and'];
  unifiedTargeting?: CriteriaUnifiedTargeting;
  unifiedAttributes?: CriteriaUnifiedAttributes;
}): CriteriaAnyRule[] => {
  const rules: CriteriaAnyRule[] = [];
  if (!values || values.length === 0) {
    return rules;
  }
  for (const rule of values) {
    // eslint-disable-next-line prefer-const
    let [key, detail] = Object.entries(rule).flat();

    // Check for loose `$eq` rules and wrap them
    if (typeof detail !== 'object') {
      console.warn('Unexpected Loose Rule:', key, detail);
      detail = { $eq: detail };
    }

    // Remove the $not caluse from the array.
    if (key === '$not' && Array.isArray(detail)) {
      /* istanbul ignore next */
      if (detail.length > 1) {
        console.error('Unsupported $not with multiple clauses inside! Taking the frist clause only.');
      }
      [detail] = detail;
    }

    // Check for Bulk Criteria
    if (key === '$or' && Array.isArray(detail)) {
      if (detail.length === 0) continue;

      // Pull the values out and rebuiild the list of values
      const bulkValue = [];
      let lastKey: string | undefined;
      let lastGroup: CriteriaRuleGroup | undefined;
      let lastComparator;
      for (const item of detail as Required<TargetingCriteria>['$or']) {
        // Split the key & group from the rule data
        const [target, logic] = Object.entries(item)[0];
        // Split group & key out of target
        let bulkGroup = '';
        let bulkKey = '';
        if (target.includes('/custom_data/')) {
          [bulkGroup, , bulkKey] = target.split('/');
          bulkKey = `custom_data/${bulkKey}`;
        } else {
          [bulkGroup, bulkKey] = target.split('/');
        }

        // Pull the logic from the rule.
        const [bulkComparator, value] = Object.entries(logic)[0];

        // Validate that we have a structure we expect
        if (lastKey && lastKey !== bulkKey) {
          console.error(`Key Mistmatch in bulk-upload rule, expected "${lastKey}" but got "${bulkKey}"`);
        }
        if (lastGroup && lastGroup !== bulkGroup) {
          console.error(`Group Mistmatch in bulk-upload rule, expected "${lastGroup}" but got "${bulkGroup}"`);
        }
        if (lastComparator && lastComparator !== bulkComparator) {
          console.error(
            `Comparator Mistmatch in bulk-upload rule, expected "${lastComparator}" but got "${bulkComparator}"`,
          );
        }

        // Update the last seen values to keep comparing against.
        lastKey = bulkKey;
        lastGroup = bulkGroup as CriteriaRuleGroup;
        lastComparator = bulkComparator;

        bulkValue.push(flattenComplexValue(value));
      }

      // Setup the structure for the rule.
      const id = gguid();
      const data: CriteriaParentRule = {
        id,
        group: CriteriaRuleGroup.BULK_UPLOAD,
        key: '',
        type: 'parent',
        details: [
          {
            parent: id,
            id: gguid(),
            comparator: lastComparator as string,
            value: bulkValue as (string | number | boolean)[],
            key: lastKey as string,
            group: lastGroup,
            type: getAttributeType(`${lastGroup || ''}/${lastKey}`, unifiedAttributes),
            duplicates: 0,
          },
        ],
      };
      rules.push(data);
      continue;
    }

    // As of 2021, only current_time has used 2 operators in a single rule
    for (let [comparator, value] of Object.entries(detail)) {
      // Currently only $contains has a $not wrapper, but we could add more in the future.
      // There are possible styles of `$not` wrapping.
      //   Used: '{ "$not": [{ "__FIELD__": { "$contains": __VALUE__ } }] }'
      // Unused: '{ "$not": { "__FIELD__": { "$contains": __VALUE__ } } }'
      // Unused: '{ "$not": { "__FIELD__": __VALUE__ } }'
      // Unused: '{ "__FIELD__": { "$not": { "$contains": __VALUE__ } } }'
      if (key === '$not') {
        key = comparator;
        [comparator] = Object.keys(detail);
        /* istanbul ignore else */
        if (typeof detail[comparator] === 'object') {
          [value] = Object.values(detail[comparator]);
        } else {
          // There should be no case where the shorthand $eq is used, but we check for it here.
          value = detail[comparator];
        }
        comparator = '$notcontains';
      } else if (comparator === '$not') {
        [comparator] = Object.keys(detail.$not);
        value = detail.$not[comparator];
        comparator = '$notcontains';
      }

      // Update Transforms for v2 Criteria
      if (key.includes('invokes/time_ago')) {
        key = key.replace('invokes/time_ago', 'last_invoked_at/total');
      }

      // Get the group from the key.
      let group: CriteriaRuleGroup | undefined;
      if (key.includes('/')) {
        [group as CriteriaRuleGroup] = key.split('/');
      }

      // Special Case: Specific Interaction, Code Points / Events
      let suffix;
      if ((group === CriteriaRuleGroup.INTERACTIONS || group === CriteriaRuleGroup.CODE_POINT) && key.includes('/')) {
        const verb = key.match(/(last_invoked_at|invokes|answers)/) || [];
        const noun =
          key.match(
            /\/(total|cf_bundle_version|cf_bundle_short_version_string|version_code|version|version_name|build|build_number|time_ago|value|id)$/,
          ) || [];
        if (verb.length <= 0 || noun.length <= 0) {
          console.error('Skipping Unknown Rule Configuration:', key);
          continue;
        }
        suffix = `${verb[0]}/${noun[1]}`;
        key = key.replace(
          /\/(last_invoked_at|invokes|answers)\/(total|cf_bundle_version|cf_bundle_short_version_string|version_code|version|version_name|build|build_number|time_ago|value|id)$/,
          '',
        );
      }
      key = key.slice(Math.max(0, key.indexOf('/') + 1));

      // Populate Rules
      let type: CriteriaRuleType = 'string';
      if (group === CriteriaRuleGroup.RANDOM) {
        key = key.replace(/\/(percent)$/, '');
        type = 'number';
      }
      type = [CriteriaRuleGroup.CODE_POINT, CriteriaRuleGroup.INTERACTIONS, CriteriaRuleGroup.FS_STATE].includes(
        group as CriteriaRuleGroup,
      )
        ? 'parent'
        : getAttributeType(`${group || ''}/${key}`, unifiedAttributes);

      // Type & Comparator Determination
      value = flattenComplexValue(value);

      if (group === CriteriaRuleGroup.APPLICATION && key === 'platform') type = 'platform';

      let isCodePoint = false;
      if (group === CriteriaRuleGroup.CODE_POINT) isCodePoint = true;

      // Check for special Interactions which show up as Events, any new Interaction should be added here
      if (
        key === 'com.apptentive#Survey#launch' ||
        key === 'com.apptentive#TextModal#launch' ||
        key === 'com.apptentive#EnjoymentDialog#no' ||
        key === 'com.apptentive#EnjoymentDialog#yes' ||
        key === 'com.apptentive#EnjoymentDialog#launch' ||
        key === 'com.apptentive#RatingDialog#launch' ||
        key === 'com.apptentive#AppleRatingDialog#not_shown' || // Deprecated: Apple Rating Dialog Not Shown
        key === 'com.apptentive#AppleRatingDialog#request' || // Deprecated: Apple Rating Dialog Requested
        key === 'com.apptentive#AppleRatingDialog#shown' ||
        key === 'com.apptentive#InAppRatingDialog#shown'
      ) {
        group = CriteriaRuleGroup.INTERACTIONS;
      }

      // Convert Negative to a Positive
      if ((type === 'duration' || comparator === '$before' || comparator === '$after') && typeof value === 'number') {
        value = Math.abs(value);
      }

      // Clean Up Fan Signals, unfortunately it went live without any review and is poorly defined.
      if (group === CriteriaRuleGroup.FS_STATE) {
        // Fan, ReclaimedFan, RepeatFan, Opportunity, LostFan, RepeatOpportunity
        suffix = key;
      }

      // Interaction Reponse Targeting should be its own group.
      let target: string | undefined;
      let interactionType;
      let survey_other = false;
      if (group === CriteriaRuleGroup.INTERACTIONS) {
        if (suffix.includes('answers/')) {
          group = CriteriaRuleGroup.IRT;
          target = key;

          // If the comparator is $exists we need to determine which one, true or falue based on the value to render correctly.
          if (comparator === '$exists' && value === false) {
            comparator = '$notexists';
          }

          // We need to find the parent interaction for the IRT rule based on its ID.
          // We group on that key to keep similar responses together, it is unused in the sub rules.
          const option = unifiedTargeting?.interaction_responses?.find((opt) => opt.key === target);
          if (option) {
            // This is likely a Survey, lets verify.
            key = option.unified_interaction_id;

            // Find the interaction type from the found parent.
            const parent = unifiedTargeting?.interactions?.find(
              (interaction) => interaction.unified_interaction_id === key,
            );
            /* istanbul ignore else */
            if (parent) {
              interactionType = parent.type;
            }

            // Get the appropriate type fot the type of Survey Question & Selected Answer (may be Other text)
            type = getAttributeType(option.type, unifiedAttributes);
            if (suffix === 'answers/value' && ['multiselect', 'multichoice'].includes(option.type)) {
              type = 'survey_question_text';
              survey_other = true;
            }
          } else {
            // Interaction was not found, not a Survey so this is likely a Note, lets verify.
            const option = unifiedTargeting?.interactions?.find((opt) => opt.unified_interaction_id === target);
            if (option) {
              // This is a Note, let's adjust the target to be the value (the select Note action) to support that.
              interactionType = option.type;
              type = getAttributeType(option.type, unifiedAttributes);
              target = value as string;
            } else {
              console.warn('Missing Interaction Response:', target);
              interactionType = '';
              target = '';
              key = '';
              value = '';
              type = ruleTypeFromValue(value);
            }
          }
        } else if (!isCodePoint) {
          const interactionExists = unifiedTargeting?.interactions?.find(
            (interaction) => interaction.unified_interaction_id === key,
          );
          if (!interactionExists) {
            console.warn('Missing Interaction:', key);

            interactionType = '';
            target = '';
            key = '';
            value = '';
            type = ruleTypeFromValue(value);
          }
        }
      }

      // Setup the structure for the rule.
      const id = gguid();
      let data: CriteriaAnyRule = {
        id,
        group: group as CriteriaRuleGroup,
        key,
        target,
        suffix,
        type,
        value: value as string | number | boolean,
        comparator,
      };

      // Check for a parent rule, if we have one add more details
      if (
        [
          CriteriaRuleGroup.CODE_POINT,
          CriteriaRuleGroup.INTERACTIONS,
          CriteriaRuleGroup.IRT,
          CriteriaRuleGroup.FS_STATE,
        ].includes(group as CriteriaRuleGroup)
      ) {
        // Prepare Details

        // Do not confuse parents by appending their first detail values.
        const { value, comparator, suffix, ...kept } = data as CriteriaChildRule;
        data = {
          ...kept,
          details: [],
        };

        const found = rules.find((r) => r.group === group && r.key === key && isParentRule(r)) as CriteriaParentRule;

        // IRT already has the correct type.
        if (group !== CriteriaRuleGroup.IRT) {
          // The traget here is confusing, it exists on the details.
          delete data.target;
          type = getAttributeType(`${group || ''}/${suffix}`, unifiedAttributes);
        } else {
          // We need to know the interaction type for the double drop down.
          data.interactionType = interactionType;
        }

        const sub_rule = {
          parent: found ? found.id : id,
          id: gguid(),
          type,
          survey_other,
          target,
          comparator,
          suffix,
          value,
          key,
        };

        // Cleanup unused and potentially confusing suffix for Fan Signals
        if (group === CriteriaRuleGroup.FS_STATE) {
          delete sub_rule.suffix;
        }

        // We already have a parent rule, add the details to that
        if (found) {
          found.details.push(sub_rule);
          continue;
        }
        data.details = [sub_rule];
      }

      rules.push(data);
    }
  }

  for (const rule of rules) {
    if (isParentRule(rule) && rule.details.length > 0) {
      rule.details = rule.details.filter((detail) => !!detail.key);

      if (rule.details.length === 0 && rule.group) {
        rule.details = getDefaultDetailsForType({ type: rule.group, parent: rule.id, key: rule.key });
      }
    }
  }

  return rules;
};

/**
 * Checks for nested rules.
 *
 * @param options The options
 * @param options.criterium Individual Criteria chunks to check: type ($and, $or, _version), values
 * @param options.interactions The collection of possible values for Interactions & Interaction Response Targeting
 * @param options.attributes The collection of custom person & device data attributes to populate rules with.
 * @returns The newly converted and consolidated rules.
 */
export const checkValues = ({
  criterium: [type, value],
  unifiedTargeting,
  unifiedAttributes,
}: {
  criterium: [string, any];
  unifiedTargeting?: CriteriaUnifiedTargeting;
  unifiedAttributes?: CriteriaUnifiedAttributes;
}): CriteriaSegment | CriteriaSegment[] | undefined => {
  switch (type) {
    case '_version': {
      // eslint-disable-next-line no-console
      console.info('Version:', value);
      return undefined;
    }
    case '$or': {
      // Higher order grouping, loop over those children
      return (value as SegmentAndRules[])
        .map((kid) =>
          Object.entries(kid).map((subCriterium) =>
            checkValues({ criterium: subCriterium, unifiedTargeting, unifiedAttributes }),
          ),
        )
        .flat()
        .filter(Boolean) as CriteriaSegment[];
    }
    case '$and': {
      // We want to combine rules that are just details
      const rules = consolidateRules({ values: value, unifiedTargeting, unifiedAttributes });
      return { id: gguid(), type, rules };
    }
    default: {
      console.error('Skipping Unknown Group Type:', type, value);
      return undefined;
    }
  }
};

/**
 * Clean up and adjust criteria for use in state.
 *
 * @param {object} options The options
 * @param {object} options.criteria The initial Criteria object to parse
 * @param {object} options.unifiedTargeting The collection of possible values for Interactions & Interaction Response Targeting
 * @param {object} options.attributes The collection of custom person & device data attributes to populate rules with.
 * @returns {object[]} The new collection of Criteria
 */
export const injestCriteria = ({
  criteria,
  unifiedTargeting,
  unifiedAttributes,
}: {
  criteria: TargetingCriteria;
  unifiedTargeting?: CriteriaUnifiedTargeting;
  unifiedAttributes?: CriteriaUnifiedAttributes;
}): CriteriaSegment[] => {
  // No criteria or keys
  if (!criteria || Object.keys(criteria).length === 0) {
    return [];
  }

  // We have something, lets build some output!
  return Object.entries(criteria)
    .map((criterium) => checkValues({ criterium, unifiedTargeting, unifiedAttributes }))
    .flat()
    .filter(Boolean) as CriteriaSegment[];
};

/**
 * Convert the verbose Criteria format to the SDK friendly format.
 *
 * @param segments The current criteria in state
 * @returns The Criteria in the original SDK format
 */
export const convertToCriteria = (segments: CriteriaSegment[]): TargetingCriteria => {
  // No segments, no criteria
  if (segments.length === 0) {
    return {};
  }

  const output: SegmentAndRules[] = [];

  for (const segment of segments) {
    const newSegment = [];

    // No rules, no criteria
    if (segment.rules.length === 0) {
      continue;
    }

    for (const newRule of segment.rules) {
      const { comparator, details, type, value } = newRule as CriteriaParentRule & CriteriaChildRule;
      let { group, key } = newRule;

      if (group === CriteriaRuleGroup.INTERACTIONS && key && (key.startsWith('com.') || key.startsWith('local#'))) {
        group = CriteriaRuleGroup.CODE_POINT;
      }

      // If the group is code_point, encode the event name to remove certain special characters in the event
      if (group === CriteriaRuleGroup.CODE_POINT) {
        key = encodeEventKey(key);
      }

      // Check for rules that require details and ensure they have details
      if (
        group &&
        [CriteriaRuleGroup.CODE_POINT, CriteriaRuleGroup.IRT, CriteriaRuleGroup.BULK_UPLOAD].includes(group) &&
        Array.isArray(details) &&
        details.length === 0
      ) {
        continue;
      }

      if (Array.isArray(details) && details.length > 0) {
        for (const detail of details) {
          const { group: detailGroup, type: detailType } = detail;
          let { comparator: detailComparator, key: detailKey, value: detailValue } = detail;

          // We need to convert back $notexists to $exists to ensure the rules are output correctly.
          if (detailComparator === '$notexists') {
            detailComparator = '$exists';
          }

          // We build out the rule details
          const detailRule: Record<string, any> = {};

          // Bulk Rules
          if (group === CriteriaRuleGroup.BULK_UPLOAD && Array.isArray(detailValue)) {
            // Validate we have all of the required parts
            if (!detail.key || (typeof detail.value === 'string' && detail.value.length === 0)) {
              continue;
            }

            detailRule.$or = detailValue.map((subValue) => {
              if (detailType === 'version') {
                return {
                  [`${detailGroup}/${detailKey}`]: {
                    [detailComparator]: {
                      _type: 'version',
                      version: subValue,
                    },
                  },
                };
              }
              return { [`${detailGroup}/${detailKey}`]: { [detailComparator]: subValue } };
            });
            newSegment.push(detailRule);
            continue;
          }

          // Fan Signals
          if (group === CriteriaRuleGroup.FS_STATE) {
            detailRule[`${group}/${key}`] = { [detailComparator]: detailValue };
            newSegment.push(detailRule);
            continue;
          }

          // IRT / Interaction Response Targeting
          if (group === CriteriaRuleGroup.IRT) {
            // Validate we have all of the required parts
            if (
              !detail.type ||
              !detail.suffix ||
              !detail.target ||
              !detailComparator ||
              detailValue === '' ||
              detailValue === undefined
            ) {
              continue;
            }

            // Notes and other Interactions differ in their targets
            if (detail.type === 'note_action') {
              detailRule[`interactions/${detail.key}/${detail.suffix}`] = { [detailComparator]: detailValue };
              newSegment.push(detailRule);
              continue;
            }

            // Swap out $notcontains for the $not wrapped $contains
            if (detailComparator === '$notcontains') {
              newSegment.push({
                $not: [
                  {
                    [`interactions/${detail.target}/${detail.suffix}`]: { $contains: detailValue },
                  },
                ],
              });
              continue;
            }

            detailRule[`interactions/${detail.target}/${detail.suffix}`] = { [detailComparator]: detailValue };
            newSegment.push(detailRule);
            continue;
          }

          // Validate we have all of the required parts
          if (!detail.type || !detail.suffix || !detailComparator || detailValue === '' || detailValue === undefined) {
            continue;
          }

          // Need to invert the time since last seen to be negative.
          if (detail.suffix === 'last_invoked_at/total') {
            detailValue = Math.abs(detailValue as number) * -1;
          }

          if (group === CriteriaRuleGroup.CODE_POINT) {
            detailKey = encodeEventKey(detail.key);
          }

          // Swap out $notcontains for the $not wrapped $contains, this block isn't used yet.
          /* istanbul ignore next */
          if (detailComparator === '$notcontains') {
            detailRule[`${group}/${key || detailKey}/${detail.suffix}`] = { $not: [{ $contains: detailValue }] };
            newSegment.push(detailRule);
            continue;
          }

          /* istanbul ignore next */
          detailRule[`${group}/${key || detailKey}/${detail.suffix}`] = { [detailComparator]: detailValue };
          newSegment.push(detailRule);
        }
      } else {
        // Validate we have all of the required parts
        if (!key || !comparator || value === '' || value === undefined) {
          continue;
        }

        const prefix = group ? `${group}/` : '';
        if (comparator === '$notcontains') {
          newSegment.push({ $not: [{ [`${prefix}${key}`]: { $contains: value } }] });
          continue;
        }
        if (type === 'version') {
          newSegment.push({ [`${prefix}${key}`]: { [comparator]: { _type: 'version', version: value } } });
          continue;
        }
        if (type === 'datetime') {
          newSegment.push({ [`${prefix}${key}`]: { [comparator]: { _type: 'datetime', sec: value } } });
          continue;
        }
        if (group === CriteriaRuleGroup.RANDOM) {
          newSegment.push({ [`${prefix}${key}/percent`]: { [comparator]: value } });
          continue;
        }
        newSegment.push({ [`${prefix}${key}`]: { [comparator]: value } });
      }
    }

    if (newSegment.length) {
      output.push({ [segment.type]: newSegment });
    }
  }

  if (output.length > 0) {
    return { $or: output };
  }
  return {};
};

/**
 * Count the number of rules in criteria.
 *
 * @param segments Criteria state object.
 * @param [segmentId] The specific Segment GUID to count.
 * @returns The number of rules in the Criteria.
 */
export const countRules = (segments: CriteriaSegment[] = [], segmentId = ''): number => {
  let rules = 0;
  if (!Array.isArray(segments)) {
    return rules;
  }
  // Typically the $or block
  for (const rule of segments) {
    if (segmentId && rule.id !== segmentId) {
      continue;
    }
    // Typically the $and block
    for (const subrule of rule.rules) {
      // Detect bulk rules
      if (subrule.group === 'bulk-upload' && isParentRule(subrule) && Array.isArray(subrule.details[0].value)) {
        rules += subrule.details[0].value.length;
      } else if (
        subrule.group &&
        [
          CriteriaRuleGroup.FS_STATE,
          CriteriaRuleGroup.CODE_POINT,
          CriteriaRuleGroup.INTERACTIONS,
          CriteriaRuleGroup.IRT,
        ].includes(subrule.group) &&
        isParentRule(subrule)
      ) {
        rules += subrule.details.length;
      } else {
        rules += 1;
      }
    }
  }
  return rules;
};
