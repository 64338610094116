import React, { PropsWithChildren, useMemo, useRef, useState } from 'react';
import { Button, Icon, SimplePopover } from 'src/components/molecules';
import { OrganizationApp } from 'src/reducers/organizations';
import { Platform, ReachByAppAndVersion, SdkVersion } from 'src/types/core';
import { bemPrefix, Formatter } from 'src/utils';
import { capitalize } from 'src/utils/titleize';

import './limited-audience.scss';

export enum Feature {
  SKIP_LOGIC = 'Skip Logic',
  TERMS_AND_CONDITIONS = 'Terms and Conditions',
  IMAGES = 'Images',
  RICH_TEXT = 'Rich Text',
  INITATORS = 'Workflow Initiators',
}

export enum FeatureContext {
  PROMPTS = 'Prompts',
  SURVEYS = 'Surveys',
}

export interface LimitedAudienceProps {
  platform: Platform;
  feature: Feature;
  context: FeatureContext;
  isFeatureUsed: boolean;
  reachNum: number;
  messageClassName?: string;
  className?: string;
}

export const SDK_VERSION_BY_FEATURE: Record<Feature, SdkVersion> = {
  [Feature.SKIP_LOGIC]: SdkVersion.SupportsSkipLogic,
  [Feature.TERMS_AND_CONDITIONS]: SdkVersion.SupportsTermsAndConditions,
  [Feature.IMAGES]: SdkVersion.SupportsRichContent,
  [Feature.RICH_TEXT]: SdkVersion.SupportsRichText,
  [Feature.INITATORS]: SdkVersion.SupportsInitiators,
};

export const FEATURE_NAME_BY_FEATURE: Record<Feature, string> = {
  [Feature.SKIP_LOGIC]: 'skip logic',
  [Feature.TERMS_AND_CONDITIONS]: 'terms and conditions or disclaimer text',
  [Feature.IMAGES]: 'images',
  [Feature.RICH_TEXT]: 'rich text',
  [Feature.INITATORS]: 'workflow initiators',
};

const CONTEXT_NAME_BY_FEATURE_CONTEXT: Record<FeatureContext, string> = {
  [FeatureContext.PROMPTS]: 'prompts',
  [FeatureContext.SURVEYS]: 'surveys',
};

const lbem = bemPrefix('limited-audience');

export const LimitedAudience: React.FC<PropsWithChildren<LimitedAudienceProps>> = ({
  platform,
  feature,
  context,
  isFeatureUsed,
  reachNum,
  messageClassName = '',
  className = '',
  children,
}) => {
  const iconRef = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  if (platform === Platform.Web) {
    return <>{children}</>;
  }

  const featureName = FEATURE_NAME_BY_FEATURE[feature];
  const sdkVersion = SDK_VERSION_BY_FEATURE[feature];
  const contextName = CONTEXT_NAME_BY_FEATURE_CONTEXT[context];

  return (
    <div className={`${lbem('container', { 'feature-used': isFeatureUsed })} ${className}`}>
      {children}
      {isFeatureUsed && (
        <>
          <div
            className={`${lbem('message', { 'no-reach': reachNum === 0 })} ${messageClassName}`}
            onMouseEnter={() => setIsTooltipOpen(true)}
            onMouseLeave={() => setIsTooltipOpen(false)}
          >
            <span>LIMITED AUDIENCE</span>
            <Icon forwardRef={iconRef} name="faCircleQuestion" />
          </div>

          <SimplePopover
            className={lbem('disabled-popover')}
            isOpen={isTooltipOpen}
            targetEl={iconRef.current}
            withArrow
            placement={SimplePopover.PopoverPlacement.top}
          >
            <div>
              {capitalize(contextName)} with {featureName} will only show to customers on an app version with Alchemer
              Digital SDK {sdkVersion} or greater, currently {reachNum}% of your audience
            </div>
          </SimplePopover>
        </>
      )}
    </div>
  );
};

export const useAverageReach = ({
  feature,
  reachByAppAndVersion,
  apps,
}: {
  feature: Feature;
  reachByAppAndVersion: ReachByAppAndVersion;
  apps: OrganizationApp[];
}): { numMobileApps: number; averageReach: number } => {
  const sdkVersion = SDK_VERSION_BY_FEATURE[feature];

  // https://en.wikipedia.org/wiki/Algorithms_for_calculating_variance#Weighted_incremental_algorithm
  return useMemo(() => {
    let numMobileApps = 0;
    let totalMau = 0;
    let averageReach = 0;

    for (const { id, platform, mau } of apps) {
      const reach = reachByAppAndVersion[id]?.[sdkVersion] || 0;

      if (platform !== Platform.Web) {
        numMobileApps += 1;

        // NOTE: Defaulting to an MAU of 1 when it's 0 or unknown is a knowingly iffy heuristic.
        // The hope here is that if _none_ of the apps have MAU, then this is the same as a basic
        // average across all the apps; if some apps have MAU and some don't, those MAU numbers
        // should be much larger than 1 and the reach for these apps shouldn't impact the result all
        // that much.
        const usedMau = mau || 1;
        totalMau += usedMau;
        const delta = reach - averageReach;
        averageReach += (usedMau / totalMau) * delta;
      }
    }

    return { numMobileApps, averageReach };
  }, [apps, reachByAppAndVersion]);
};

export interface IMTLimitedAudienceProps {
  feature: Feature;
  context: FeatureContext;
  isFeatureUsed: boolean;
  numMobileApps: number;
  averageReach: number;
  onManageApps?: () => void;
  messageClassName?: string;
  className?: string;
}

export const IMTLimitedAudience: React.FC<PropsWithChildren<IMTLimitedAudienceProps>> = ({
  feature,
  context,
  isFeatureUsed,
  averageReach,
  numMobileApps,
  messageClassName = '',
  className = '',
  onManageApps,
  children,
}) => {
  const iconRef = useRef(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const featureName = FEATURE_NAME_BY_FEATURE[feature];
  const sdkVersion = SDK_VERSION_BY_FEATURE[feature];
  const contextName = CONTEXT_NAME_BY_FEATURE_CONTEXT[context];

  const showLimitedAudience = isFeatureUsed && numMobileApps !== 0 && averageReach < 80;

  return (
    <div className={`${lbem('container', { 'feature-used': isFeatureUsed, 'multi-app': true })} ${className}`}>
      {children}
      {showLimitedAudience && (
        <>
          <div
            className={`${lbem('message', { 'no-reach': averageReach === 0 })} ${messageClassName}`}
            onMouseEnter={() => setIsTooltipOpen(true)}
          >
            <span>LIMITED AUDIENCE</span>
            <Icon forwardRef={iconRef} name="faCircleQuestion" />
          </div>

          <SimplePopover
            className={lbem('disabled-popover')}
            isOpen={isTooltipOpen}
            onClose={() => setIsTooltipOpen(false)}
            targetEl={iconRef.current}
            withArrow
            placement={SimplePopover.PopoverPlacement.top}
          >
            <div className={lbem('disabled-inner')}>
              {capitalize(contextName)} with {featureName} will only show to customers on an app version with Alchemer
              Digital SDK {sdkVersion} or greater, currently {Formatter.number(averageReach)}% of your audience{' '}
              {numMobileApps === 1 ? 'in 1 mobile app' : `across ${numMobileApps} mobile apps`}
            </div>
            {onManageApps && (
              <div className={lbem('manage-apps-outer')}>
                <Button normalize className={lbem('manage-apps')} onClick={onManageApps}>
                  Manage Apps <Icon isInline name="externalLink" />
                </Button>
              </div>
            )}
          </SimplePopover>
        </>
      )}
    </div>
  );
};

export interface NoAudienceProps {
  feature: Feature;
  context: FeatureContext;
  className?: string;
  targetRef: React.RefObject<HTMLElement>;
}

const nbem = bemPrefix('no-audience');

export const NoAudience: React.FC<PropsWithChildren<NoAudienceProps>> = ({
  feature,
  context,
  className = '',
  targetRef,
  children,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const featureName = FEATURE_NAME_BY_FEATURE[feature];
  const sdkVersion = SDK_VERSION_BY_FEATURE[feature];
  const contextName = CONTEXT_NAME_BY_FEATURE_CONTEXT[context];
  const requiresText = {
    [Feature.SKIP_LOGIC]: 'requires',
    [Feature.TERMS_AND_CONDITIONS]: 'require',
    [Feature.IMAGES]: 'require',
    [Feature.RICH_TEXT]: 'requires',
    [Feature.INITATORS]: 'require',
  }[feature];

  return (
    <div
      className={`${nbem('container')} ${className}`}
      onMouseEnter={() => setIsTooltipOpen(true)}
      onMouseLeave={() => setIsTooltipOpen(false)}
    >
      {children}
      <SimplePopover
        className={nbem('disabled-popover')}
        isOpen={isTooltipOpen}
        targetEl={targetRef.current}
        withArrow
        placement={SimplePopover.PopoverPlacement.top}
      >
        <div>
          {capitalize(featureName)} in {contextName} {requiresText} Alchemer Digital SDK {sdkVersion} or greater, which
          is not used in any app version currently installed by your customers.
        </div>
      </SimplePopover>
    </div>
  );
};
